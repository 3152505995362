export const googleSignInWebClientID = '429025886256-h7je7h12gjoi5o4qri7grhea6fh89p02.apps.googleusercontent.com'
export const microsoftClienteId = 'e0fd900e-e7f8-46ff-a4a9-66a52d38151e'
export const urlDocumentacionParametrosApp='https://farmavalue-my.sharepoint.com/:u:/p/dsolano/ESeeS7JdHaJDrRksKhfbo3MByczDMtSb2bK4rnuySufy-w?e=Jc8kAM'
//export const microsoftClienteId = '660434e2-53e2-473a-a551-31537f382edb'

export const urlDataAdapterProduction = "https://portal.3c.group/" // URL de producción

export const urlDataAdapterDevelopment = "http://172.17.173.59:3001" // URL de Desarrollo Wilmer Corrales

//export const urlDataAdapterDevelopment = "http://10.103.0.21:3000" // URL de Desarrollo Luis Arita

export const urlApisDataAdapterProduction = "https://apis.ccc-it.net" // Apis HN 
// export const urlApisDataAdapterDevelopment = "https://apis.ccc-it.net" // URL de Desarrollo
export const urlApisDataAdapterDevelopment = "http://10.101.20.30:3000" // URL de Desarrollo 

export const urlApisFEDataAdapterProduction = "https://fe-app.3c.group" // Apis FE 
// export const urlApisFEDataAdapterDevelopment = "https://fe-app.3c.group" // URL de Desarrollo
export const urlApisFEDataAdapterDevelopment = "http://localhost:3004/" // URL de Desarrollo 

export const urlApisCRDataAdapterProduction = "https://cr-app.3c.group" // Apis CR 
// export const urlApisCRDataAdapterDevelopment = "https://cr-app.3c.group" // URL de Desarrollo
export const urlApisCRDataAdapterDevelopment = "http://localhost:3001/" // URL de Desarrollo 

export const urlApisFDDataAdapterProduction = "https://fd-app.3c.group" // Apis FD 
// export const urlApisFDDataAdapterDevelopment = "https://fd-app.3c.group" // URL de Desarrollo
export const urlApisFDDataAdapterDevelopment = "http://localhost:3001/" // URL de Desarrollo 

export const urlApisFGDataAdapterProduction = "https://fg-app.3c.group" // Apis FG 
// export const urlApisFGDataAdapterDevelopment = "https://fg-app.3c.group" // URL de Desarrollo
export const urlApisFGDataAdapterDevelopment = "http://localhost:3001/" // URL de Desarrollo 

export const urlApisFMDataAdapterProduction = "https://fm-app.3c.group" // Apis FM 
// export const urlApisFMDataAdapterDevelopment = "https://fm-app.3c.group" // URL de Desarrollo
export const urlApisFMDataAdapterDevelopment = "http://localhost:3001/" // URL de Desarrollo 

export const urlApisFVDataAdapterProduction = "https://fv-app.3c.group" // Apis FV
// export const urlApisFVDataAdapterDevelopment = "https://fv-app.3c.group" // URL de Desarrollo
export const urlApisFVDataAdapterDevelopment = "http://localhost:3001/" // URL de Desarrollo 

export const urlApisFPDataAdapterProduction = "https://fp-app.3c.group" // Apis FP 
// export const urlApisFPDataAdapterDevelopment = "https://fp-app.3c.group" // URL de Desarrollo
export const urlApisFPDataAdapterDevelopment = "http://localhost:3001/" // URL de Desarrollo 

export const habilitarLoginPassword = configSegunEntorno("habilitarLoginPassword")

function configSegunEntorno(variable) {
    let variables = []
    if (window.location.hostname == 'localhost') {
        variables["habilitarLoginPassword"] = false
    } else {
        variables["habilitarLoginPassword"] = false
    }

    return variables[variable]
}
